.inicio {
  &-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &-img {
      width: 10vw;
    }
  }
}
