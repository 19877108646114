.icon {
  color: #494c62;
  font-weight: 800;
  border-radius: 15px;
  margin-left: 16px;
}
.userLogMenu {
  border-radius: 4px;
  position: absolute;
  background-color: #fff;
  box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.25);
  z-index: 3;
}
.logsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0px 16px 12px;
  padding-top: 10px;
}
.userLogContainer {
  display: flex;
  align-items: center;
}
.createIndicator {
  background: #2196f3;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin-right: 8px;
}
.updateIndicator {
  background: #f82b43;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin-right: 8px;
}
.logText {
  font-size: 10px !important;
  color: #666 !important;
}
