.rotate-center {
  animation: rotate-center 1.4s ease-in-out infinite both;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
