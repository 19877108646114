.h-100-vh {
  height: 100vh;
}
.title-color {
  color: #828282;
}

.zindex-modal {
  z-index: 1000;
}

.bg-cadastro-contador {
  background: #84997a;
  background: linear-gradient(90deg, #84997a 0%, #697c60 100%);
}

.line {
  width: 120vw;
  z-index: 0;
  top: 344px;
  right: -116px;
}

.line-elipse-contador {
  width: 48vw;
  z-index: 0;
  left: -15%;
  bottom: -179px;
}

.button-submit-contador {
  background: #84997a !important;
}

.button-submit-contador:hover {
  background: #697c60 !important;
}
