.center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.root {
  flex: 1;
  height: calc(100% - 80px);
  display: flex;
  background-color: #fff;
}

.content {
  min-height: 100vh;
  width: calc(100vw - 80px);
  transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) !important;

  &-open {
    width: calc(100vw - 292px) !important;
    transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) !important;
  }
}

.title {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #636363;
  padding: 20px;
}

.backbutton {
  cursor: pointer;
}

.drwPaper {
  width: 295px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) !important;

  &-open {
    width: 292px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) !important;
  }
}

.drwPaperClose {
  width: 80px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) !important;

  &-exit {
    width: 77px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) !important;
  }
}

.drawerPaper {
  background-color: #fff;
  border-right: 3px solid #d0dbe3 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.01);
  z-index: 1300;
  position: fixed;
  white-space: nowrap;
  width: 295px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) !important;
  height: 100%;
}

.drawerPaperClose {
  overflow-x: hidden;
  transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) !important;
  width: 80px;
  height: 100%;
}

.mainLogo {
  transition: all 0.3s ease-in-out;
  margin-top: 20px;

  &-icon {
    height: 45px;
  }
}

.itens {
  height: 50px;
  border-radius: 16px !important;
  padding: 0 15px;
}

.listItem {
  background: #edeef0 !important;

  &-text {
    color: #697c60;
    font-weight: 700;
    padding-left: 20px;
  }

  &-icon {
    font-size: 20px;
  }

  &-exit {
    background-color: #e44a42 !important;
  }
}

.listItemActive {
  &-open {
    background: linear-gradient(
      90deg,
      #697c60 2%,
      #e8f1ee 2%,
      #e8f1ee 100%
    ) !important;
  }

  &-close {
    background: #e8f1ee !important;
  }

  &-text {
    color: #697c60;
    font-weight: 700;
    padding-left: 20px;
  }

  &-icon {
    color: #697c60 !important;
    font-size: 20px;
  }
}

.listSubItem {
  margin-left: 20px !important;

  &-text {
    color: #697c60;
    font-size: 12.5px;
    font-weight: 600;
  }
}
