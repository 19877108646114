.appBar {
  position: fixed;
  z-index: 1200 + 1;
}
.topDrwPaper {
  width: calc(100% - 295px) !important;
  background-color: #fff !important;
  height: 82px;
  display: flex;
  justify-content: center;
  box-shadow: none !important;
  transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) !important;
}
.topDrwPaperClose {
  width: calc(100% - 80px) !important;
  transition: width 300ms cubic-bezier(0.4, 0, 0.6, 1) !important;
}
.appBarShift {
  margin-left: 280px;
  background-color: #eb5757;
}
.searchMenuDescription {
  color: #666 !important;
}
.menuContainer {
  position: absolute;
  top: 64px;
  left: 112px;
  background-color: #fff;
  box-shadow: -1px 7px 12px -2px rgba(0, 0, 0, 0.3) !important;
  max-height: 500px !important;
  width: 380px !important;
  overflow: auto !important;
}
.menuItem {
  white-space: pre-wrap !important;
  padding: 15px;
}
.flexGrow {
  flex-grow: 1;
}
.signOutButton {
  margin-left: 16px;
  justify-self: end;
  align-self: center;
}
.containerTopBar {
  display: flex;
  flex: 1;
}
.search {
  height: 37px;
  top: 5px;
  position: relative;
  color: #494c62;
  border-radius: 8px;
  background-color: #e5e5e5;
  margin-bottom: 10px;
  width: 100%;
}
.searchIcon {
  padding: 0 16px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputRoot {
  position: relative;
  color: inherit;
}
.inputInput {
  padding: 7px 8px 8px 0 !important;
  padding-left: calc(1em + 32px) !important;
  font-size: 14px !important;
  width: 100% !important;
}

@media screen and (min-width: 576px) {
  .search {
    margin-left: 8px;
    width: auto;
  }
  .inputInput {
    transition: 0.5s;
    width: 30ch !important;
  }
  .inputInput:focus {
    width: 35ch !important;
  }
}

.subheader {
  background-color: #fff;
}
