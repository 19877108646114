.appbar-fullscreen {
  position: "relative";
}

.title-fullscreen {
  margin-left: 2px;
  color: "white";
  flex: 1;
}

.logo-report-bar {
  height: 45px;
  filter: grayscale(100) brightness(100);
}
