#conteudo_mini * {
  margin: 0 !important;
  font-family: sans-serif;
}

#conteudo_mini {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

#conteudo_mini h6 {
  margin: 1.5px;
  font-size: 9px;
}

.itens_text {
  text-align: start;
  font-size: 9px;
  text-transform: uppercase;
  padding: 2px 0.5rem !important;
  margin: 0;
}

.descricao_produto {
  overflow: hidden;
  text-overflow: clip;
  width: 78px;
  max-height: 22px;
}

.itens_border {
  border-width: 0 0 0.1px 0;
  border-color: black;
  border-style: solid;
}

.texto_header {
  text-align: start;
  font-size: 9px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.list_valores {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 10px;
  font-weight: 700;
}

.valor_list {
  text-align: right;
  padding-left: 50px;
}

.danfe_cancelada {
  opacity: 0.4;
  position: absolute;
  top: 25%;
  left: 10%;
  font-size: 12vw;
  color: #bf3030;
}