.validationInput {
  width: 100%;
  min-width: 348px;
  height: auto;

  &-caracter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    border: 2px solid silver;
    border-radius: 20px;
    color: #5e5e5e;
  }
}
