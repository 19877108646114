#not-found {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f5;
}

#button-not-found {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #e3e7f2;
  color: #2152f3;
  padding: 1rem 2.5rem;
  margin: 20px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  box-shadow: none !important;
}

#button-not-found i {
  font-size: 20px;
  color: #2152f3;
  margin-right: 10px;
}

#button-not-found:hover {
  background-color: #DADFF5;
}

#container-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#container-not-found img {
  padding-left: 13rem;
  width: 1300px;
}