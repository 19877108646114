.list {
  max-height: 320px;
}
.actions {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.info {
  margin-top: 8px;
}
.dropzone {
  border: 1px dashed #e0e0e0;
  padding: 48px;
  outline: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.dropZone:hover {
  background-color: #fafafa;
  opacity: 0.5;
  cursor: pointer;
}
.dragActive {
  background-color: #fafafa;
  opacity: 0.5;
}
